import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Other/seo';

const NotFoundPage = () => (
	<Layout>
		<div className='center-container'>
			<div className='row middle-xs'>
				<div className='col-xs-12 text-center'>
					<SEO title='Florin Pop - 404 Not found' />
					<h1>NOT FOUND</h1>
					<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
					<br />
					<img
						src='https://media.giphy.com/media/8L0Pky6C83SzkzU55a/giphy.gif'
						alt='404 img'
					/>
				</div>
			</div>
		</div>
	</Layout>
);

export default NotFoundPage;
